import { React, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { deleteItem, updateItem } from "../graphql/mutations";
import { API, Storage } from "aws-amplify";
import { v4 as uuid } from "uuid";
import LoadableCardImage from "./LoadableCardImage";

const UpdateItem = ({ item, onUpload }) => {
  const [uploadState, setUploadState] = useState("");
  const [itemData, setItemData] = useState(item);
  const [imageData, setImageData] = useState(null);

  const onError = (err) => {
    setUploadState(err);
  };

  const uploadItemAsync = async () => {
    // console.log("itemData", itemData);

    const {
      id,
      name,
      description,
      shortDescription,
      itemPrice,
      dayOnePrice,
      dailyPrice,
      quantity,
      imgSrc,
      category,
    } = itemData;

    if (imageData != null) {
      var { key } = await Storage.put(`${uuid()}.jpg`, imageData);
    } else {
      key = imgSrc;
    }

    const updateItemInput = {
      id,
      name,
      description,
      shortDescription,
      itemPrice,
      dayOnePrice,
      dailyPrice,
      quantity,
      imgSrc: key,
      category,
    };
    // console.log("updateItemInput", updateItemInput);
    try {
      await API.graphql({
        query: updateItem,
        variables: { input: updateItemInput },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      onUpload();
    } catch (err) {
      onError("Upload error: " + err.errors[0].message);
    }
  };

  const deleteItemAsync = async () => {
    // console.log("itemData", itemData);

    var id = itemData.id;

    const deleteItemInput = {
      id: id,
    };
    // console.log("deleteItemInput", deleteItemInput);
    try {
      await API.graphql({
        query: deleteItem,
        variables: { input: deleteItemInput },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      onUpload();
    } catch (err) {
      onError("Upload error: " + err.errors[0].message);
    }
  };

  return (
    <Box p={2} sx={{ backgroundColor: "primary.white", borderRadius: 2 }}>
      {/* <Typography variant="h5"> Update An Existing Item</Typography> */}
      <LoadableCardImage
        imgSrc={itemData.imgSrc}
        imgSizePreset="360w"
        imgAltText={itemData.imgAlt}
        sx={{
          maxHeight: 240,
          maxWidth: "md",
          margin: "0 auto",
          objectFit: "contain",
        }}
      />

      <br />
      <Grid
        container
        spacing={{ xs: 2, md: 3, lg: 4 }}
        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        sx={{ fontFamily: "Roboto" }}
      >
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextField
            sx={{ width: "100%" }}
            label="Name"
            value={itemData.name}
            onChange={(e) => setItemData({ ...itemData, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextField
            sx={{ width: "100%" }}
            label="Short Description"
            value={itemData.shortDescription}
            onChange={(e) =>
              setItemData({ ...itemData, shortDescription: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            sx={{ width: "100%" }}
            multiline
            label="Description"
            value={itemData.description}
            onChange={(e) =>
              setItemData({ ...itemData, description: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={4} sm={6} md={3} lg={3}>
          <TextField
            sx={{ width: "100%" }}
            label="Item Price"
            value={itemData.itemPrice}
            type="number"
            step="0.01"
            min="0"
            onChange={(e) =>
              setItemData({ ...itemData, itemPrice: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={4} sm={6} md={3} lg={3}>
          <TextField
            sx={{ width: "100%" }}
            label="Day One Price"
            value={itemData.dayOnePrice}
            type="number"
            step="0.01"
            min="0"
            onChange={(e) =>
              setItemData({ ...itemData, dayOnePrice: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={4} sm={6} md={3} lg={3}>
          <TextField
            sx={{ width: "100%" }}
            label="Daily Price"
            value={itemData.dailyPrice}
            type="number"
            step="0.01"
            min="0"
            onChange={(e) =>
              setItemData({ ...itemData, dailyPrice: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={4} sm={6} md={3} lg={3}>
          <TextField
            sx={{ width: "100%" }}
            label="Quantity"
            value={itemData.quantity}
            type="number"
            step="1"
            min="0"
            onChange={(e) =>
              setItemData({ ...itemData, quantity: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={8} sm={6} md={6} lg={6}>
          <TextField
            sx={{ width: "100%" }}
            select
            label="Category"
            value={itemData.category || ""}
            onChange={(e) =>
              setItemData({ ...itemData, category: e.target.value })
            }
          >
            <MenuItem value={"backpack"}>Backpacks</MenuItem>
            <MenuItem value={"hammock"}>Chairs/Hammocks</MenuItem>
            <MenuItem value={"sleeping_bag"}>Sleeping Bags/Pads</MenuItem>
            <MenuItem value={"sup_kayak"}>Sup/Kayak</MenuItem>
            <MenuItem value={"tent"}>Tents</MenuItem>
            <MenuItem value={"misc"}>Misc</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={4} sm={6} md={6} lg={6}>
          <Button variant="contained" component="label" sx={{ width: "100%" }}>
            <AddPhotoAlternateIcon />
            Choose Image
            <input
              type="file"
              hidden
              accept="image/jpeg"
              label="Upload Image"
              onChange={(e) => setImageData(e.target.files[0])}
            />
          </Button>
        </Grid>
        {/* <TextField
          label="Image Alt Text"
          value={itemData.alt}
          onChange={(e) => setItemData({ ...itemData, alt: e.target.value })}
        /> */}
        <Grid item xs={4} sm={6} md={6} lg={6}>
          <Button
            sx={{
              width: "100%",
              color: "primary.white",
              backgroundColor: "primary.dark",
              transition: "background .6s",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              textAlign: "center",
            }}
            onClick={uploadItemAsync}
          >
            <UploadIcon />
            Update Item
          </Button>
        </Grid>
        <Grid item xs={4} sm={6} md={6} lg={6}>
          <Button
            sx={{
              width: "100%",
              margin: "0 auto",
              color: "primary.white",
              backgroundColor: "primary.dark",
              transition: "background .6s",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              margin: "0 auto",
              textAlign: "center",
            }}
            onClick={deleteItemAsync}
          >
            <DeleteForeverIcon />
            Delete Item
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateItem;
