import { React, useEffect, useState } from "react";
import {
  Typography,
  Modal,
  Box,
  Button,
  CircularProgress,
  Grid,
  Container,
} from "@mui/material/";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { listItems } from "../graphql/queries";
import { Amplify, API } from "aws-amplify";
import awsExports from "../aws-exports";
import CustomGridItem from "../components/CustomGridItem";
import AddItem from "../components/AddItem";
import UpdateItem from "../components/UpdateItem";

Amplify.configure(awsExports);

function Admin() {
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState({
    dayOnePrice: 0,
    dailyPrice: 0,
    imgSrc: "placeholder.jpg",
  });
  const [loading, setLoading] = useState(false);
  const [loadedItems, setLoadedItems] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = (item) => {
    setModalOpen(true);
    setCurrentItem(item);
  };
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    fetchItems();
  }, []);
  async function fetchItems() {
    setLoading(true);
    try {
      var itemData = await API.graphql({
        query: listItems,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const items = itemData.data.listItems.items;
      setItems(items);
      const lItems = await Promise.all(
        items.map(async (item) => {
          return (
            <CustomGridItem
              key={item.id}
              item={item}
              action={handleModalOpen}
              actionArgs={item}
            ></CustomGridItem>
          );
        })
      );
      setLoadedItems(lItems);
    } catch (err) {
      console.log("error fetching items", err);
    }
    setLoading(false);
  }

  function BasicAdminModal() {
    return (
      <div>
        <Modal
          keepMounted
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: 360,
              maxHeight: 800,
              width: "md",
              bgcolor: "background.paper",
              borderRadius: 4,
              boxShadow: 24,
              p: 3,
            }}
          >
            <UpdateItem
              item={currentItem}
              onUpload={() => {
                fetchItems();
                handleModalClose();
              }}
            />
          </Box>
        </Modal>
      </div>
    );
  }

  return (
    <Container variant="fullHeight">
      <Typography variant="h3" sx={{ p: 4 }}>
        Admin Portal
      </Typography>
      <Authenticator hideSignUp={true}>
        {({ signOut, user }) => (
          <main>
            <Container>
              <BasicAdminModal />
              <Box sx={{ backgroundColor: "primary.light", p: 4 }}>
                <Typography variant="h6">
                  Hello you are signed in as:
                </Typography>
                <Typography variant="body2">{user.attributes.email}</Typography>
                <Typography variant="body1">
                  Welcome to the Admin Portal. Use this to add or edit
                  inventory.
                </Typography>
                <Typography variant="h5">or</Typography>
                <Button
                  onClick={signOut}
                  sx={{ backgroundColor: "white", marginTop: 2 }}
                >
                  Sign out
                </Button>
              </Box>
              <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                <AddItem
                  onUpload={() => {
                    fetchItems();
                  }}
                />
              </Box>
              {loading && (
                <Box sx={{ display: "flex", textAlign: "center" }}>
                  <CircularProgress size="8rem" sx={{ margin: "0 auto" }} />
                </Box>
              )}
              {!loading && loadedItems.length <= 0 && (
                <Typography variant="h4">Sorry! No Results.</Typography>
              )}
              {!loading && loadedItems.length > 0 && (
                <div>
                  <Typography variant="h4">Existing Items</Typography>
                  <br />
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3, lg: 4 }}
                    columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}
                  >
                    {loadedItems}
                  </Grid>
                  <br />
                </div>
              )}
            </Container>
          </main>
        )}
      </Authenticator>
    </Container>
  );
}

export default Admin;
