import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Gear from "./pages/Gear";
import Admin from "./pages/Admin";
import Release from "./pages/Release";

import Header from "./components/Header";
import Footer from "./components/Footer";

import { theme } from "./themes/Theme";

import { ThemeProvider } from "@mui/material/styles";

import { Helmet } from "react-helmet";

const s3_cloudfront_test = "https://d4sisy0co3k51.cloudfront.net/";
const s3_cloudfront_dev = "https://d19mg0lf0ogjff.cloudfront.net/";

export default function App() {
  const appName = "Crux Utah Rental Equipment";
  const appShortName = "Crux Rentals";
  const gitVersion = process.env.REACT_APP_GIT_VERSION;
  // console.log(process.env.NODE_ENV);
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="theme-color" content={theme.palette.primary.main} />
        <title>{appShortName}</title>
      </Helmet>
      <div className="App">
        <Header theme={theme} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="gear" element={<Gear />} />
          <Route path="admin" element={<Admin />} />
          <Route path="release" element={<Release />} />
        </Routes>
        <Footer appName={appName} gitVersion={gitVersion} />
      </div>
    </ThemeProvider>
  );
}
