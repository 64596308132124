import React from "react";

import { Button, Box, Container } from "@mui/material/";

const Release = () => {
  return (
    <Container variant="fullHeight" maxWidth="md" disableGutters>
      <Box
        sx={{
          p: 4,
        }}
      >
        <h3>
          CRUX UTAH RENTAL EQUIPMENT RELEASE OF LIABILITY: PLEASE READ CAREFULLY
          BEFORE SIGNING
        </h3>
        <p>
          1. RISKS OF ACTIVITY: I understand that the activity for which this
          equipment is rented has hazardous potential and that while engaged in
          its use, I may encounter or be exposed to any number of risks
          including but not limited to: equipment failures and negligence by
          others. I understand that as a consequence of these risks, I may be
          seriously hurt, disabled or die from the resulting injuries, and that
          rental property may also be damaged.
        </p>
        <p>
          2. ASSUMPTION OF THE RISKS: I hereby freely assume these and other
          relevant risks, and any harm, injury or loss that may occur to me or
          the rental property as a result of my use of this rental
          equipment-including, but not limited to, any risks caused by the
          negligence of any Crux Utah Rental Equipment employee or by any other
          individual.
        </p>
        <p>
          3. RELEASE OF LIABILITY: I hereby release Crux Utah Rental Equipment,
          the manufacturer of the rental equipment, and their employees,
          officers, directors, shareholders and owners (the "Released Parties")
          from all liabilities, causes of action, claims and demands that arise
          in any way from any injury, death, or loss or harm that I sustain or
          that any other person or any property sustains as a result of my use
          of the rental equipment. This release includes, but is not limited to
          claims based on: negligence, including in the selection, maintenance
          and inspection of the rental equipment or failure to provide proper
          instruction, technical direction or safety equipment. I agree not to
          sue or make a claim against the Released Parties for injuries or
          damages relating to my use of the rental equipment.
        </p>
        <p>
          4. INDEMNIFICATION HOLD HARMLESS AND DEFEND: I promise to compensate,
          hold harmless and defend the Released Parties (defined in Section 3)
          against any and all claims to which Section 3 of this agreement
          applies, including claims for their own negligence. I also promise to
          compensate and hold harmless and defend the Released Parties against
          any and all claims for my own negligence, and any other claim arising
          from my use of the rental equipment. In accordance with these
          promises, I will reimburse the Released Parties for any damages,
          reasonable settlements and defense costs, including attorney's fees,
          they incur because of any such claims made against them. I agree that
          in the event of my death or disability, the terms of this agreement,
          including the indemnification obligation in this Section, will be
          binding on my estate, and my personal representative, executor,
          administrator or guardian will be obligated to respect and enforce
          them.{" "}
        </p>
        <p>
          5. REPRESENTATIONS AND AGREEMENT: I agree that I am solely responsible
          for my health and safety, and certify that I have the experience,
          skill and ability necessary to use the rental equipment, and that I am
          in good physical health. I agree that I am responsible for knowing how
          to properly use the rental equipment and for obtaining necessary
          instruction if I need it. If I believe the rental equipment is not
          functioning properly, I will stop using it and will return it to the
          store for inspection, repair or adjustment by a qualified technician.
          I agree not to misuse or abuse the rental equipment and to use caution
          while using it. I will not let anyone else use the rental equipment
          while it is in my care.
        </p>
        <p>
          {" "}
          6. SEVERABILITY: I agree that the purpose of this agreement is that it
          shall be an enforceable release of liability and indemnity as broad
          and inclusive as is permitted by law. I agree that if any portion or
          provision of this agreement is found to be invalid or unenforceable,
          then the remainder will continue in full force and effect. I also
          agree that any invalid provision will be modified or partially
          enforced to the maximum extent permitted by law to carry out the
          purpose of this agreement.{" "}
        </p>
        <p>
          7. APPLICABLE LAW AND ATTORNEY'S FEES: This agreement is governed by
          and construed in accordance with the laws of the state of Utah,
          without any reference to choice of law rules. In any litigation in
          which the validity or enforceability of this agreement is contested, I
          agree that any party contesting the agreement will pay all attorney's
          fees and costs of the parties seeking to uphold the agreement. I
          understand that nothing in this agreement is intended to release
          claims for gross negligence, intentional or reckless misconduct, or
          any other liabilities that Utah law does not permit{" "}
        </p>
        <p>
          I accept this rental equipment ‘as is’ and without any warranty,
          express or implied, beyond those in this agreement, and in the
          manufacturer's written warranty, if any. I agree to return all rental
          equipment in clean and dry condition, and will replace at full retail
          value any rental equipment not returned to Crux Utah Rental Equipment.
          To avoid any additional rental charges, I agree to return the rental
          equipment by the agreed date. I agree to be fully responsible for the
          care of this rental equipment and will reimburse Crux Utah Rental
          Equipment for any loss or damage to it, other than reasonable wear and
          tear.
        </p>
        {/* <p>
          BY CLICKING BOX AND SIGNING BELOW, YOU ACCEPT ALL THE TERMS OF THIS
          AGREEMENT.
        </p> */}
        <p>
          I AM A LEGAL ADULT AND HAVE FULLY INFORMED MYSELF OF THE CONTENTS OF
          THIS AGREEMENT BY READING BEFORE SIGNING BELOW. NO ORAL
          REPRESENTATIONS, STATEMENTS OR OTHER ENCOURAGEMENT TO SIGN THIS
          RELEASE HAVE BEEN MADE APART FROM WHAT IS CONTAINED IN THIS DOCUMENT.
        </p>
      </Box>
    </Container>
  );
};

export default Release;
