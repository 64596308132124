// example theme.js

import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// Fonts
import "@fontsource/archivo-black";
import "@fontsource/oxygen-mono";
import "@fontsource/rubik-mono-one";
import "@fontsource/heebo";
import "@fontsource/roboto";

const fontPrimary = "Rubik Mono One";
// const fontPrimary = "Roboto";
const fontCased = "Roboto";

// Colors
const textPrimary = "#ededee";
const textSecondary = "#dbc8ab";

var myTheme = createTheme({
  shadows: [0, 2, 4, 6, 8, 16, 24, 36, 42],
  spacing: [0, 4, 8, 16, 32, 64, 72, 80],
  shape: {
    borderRadius: 6,
  },
  typography: {
    default: {
      fontFamily: fontPrimary,
      color: textPrimary,
    },
    // h1: {},
    // h2: {},
    // h3: {},
    // h4: {},
    // h5: {},
    // h6: {},
    // subtitle1: {},
    // subtitle2: {},
    body1: { fontFamily: "Helvetica" },
    body2: { fontFamily: "Helvetica" },
    button: { fontFamily: fontPrimary },
    // caption: {},
    // overline: {},
    buttonPrimary: {
      fontFamily: fontPrimary,
      // color: textSecondary
    },
    buttonSecondary: {
      fontFamily: fontPrimary,
      // color: textSecondary
    },
    fontFamily: fontPrimary,
    color: textSecondary,
  },
  palette: {
    primary: {
      main: "#2a5465",
      // light: "#588093",
      light: "#ccdfe8",
      dark: "#002b3b",
      white: "#EDEDEE",
    },
    secondary: {
      main: "#8d3829",
      light: "#c16552",
      dark: "#5a0800",
    },
    text: {
      primary: "#061a2e",
      secondary: "#46505A",
    },
  },
  components: {
    MuiContainer: {
      variants: [
        {
          props: { variant: "fullHeight" },
          style: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            textAlign: "center",
            marginTop: "56px",
          },
        },
      ],
    },
    MuiMenuItem: {
      variants: [
        {
          props: { variant: "header" },
          style: {
            fontFamily: fontPrimary,
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "centeredPrimary" },
          style: {
            maxWidth: 200,
            backgroundColor: "primary",
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variants: "fontCased" },
          style: {
            fontFamily: "fontCased",
          },
        },
      ],
    },
  },
});

export const theme = responsiveFontSizes(myTheme);
