import React from "react";

import {
  Typography,
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  Container,
  Grid,
} from "@mui/material/";

import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import crux_logo_main from "../img/crux_logo_main.png";
import logo_background from "../img/backgrounds/logo_background.jpg";
import slogan from "../img/slogan.png";

import patch_backpack from "../img/patches/crux patch backpacks-01.png";
import patch_hammock from "../img/patches/crux patch hammock-01.png";
import patch_misc from "../img/patches/crux patch misc-01.png";
import patch_sleeping_bag from "../img/patches/crux patch sleeping bags-01.png";
import patch_sup_kayak from "../img/patches/crux patch sup kayak-01.png";
import patch_tent from "../img/patches/crux patch tents-01.png";

const Home = () => {
  const patches = [
    { key: "backpack", img: patch_backpack },
    { key: "hammock", img: patch_hammock },
    { key: "sleeping_bag", img: patch_sleeping_bag },
    { key: "sup_kayak", img: patch_sup_kayak },
    { key: "tent", img: patch_tent },
    { key: "misc", img: patch_misc },
  ];
  var patchCards = patches.map((patch) => {
    return (
      <Grid
        key={patch.key}
        item
        xs={2}
        sm={4}
        md={4}
        lg={4}
        component={NavLink}
        to={"/gear?category=" + patch.key}
      >
        <Card sx={{ borderRadius: 6 }}>
          <CardMedia
            sx={{
              transition: "background .6s",
              backgroundColor: "primary.light",
              // borderRadius: 8,
              "&:hover": {
                backgroundColor: "white",
                opacity: [0.9, 0.8, 0.7],
              },
            }}
            key={patch.key}
            component="img"
            maxheight={200}
            image={patch.img}
            alt="Crux Utah Rental Equipment Logo"
          />
        </Card>
      </Grid>
    );
  });
  return (
    <Container maxWidth="xl" disableGutters={true} variant="fullHeight">
      <Box
        sx={{
          maxHeight: 1000,
        }}
      >
        <Card
          display="flex"
          width={1}
          maxheight={1000}
          justifycontent="center"
          alignitems="center"
          style={{
            backgroundImage: `url(${logo_background})`,
            backgroundSize: "cover",
            // height: "100vh",
            color: "#f5f5f5",
            borderRadius: 0,
          }}
        >
          <CardMedia
            component="img"
            image={crux_logo_main}
            alt="Crux Utah Rental Equipment Logo"
            sx={{ maxWidth: "md", margin: "0 auto" }}
          />

          <CardContent sx={{ textAlign: "center" }}>
            <Button
              component={HashLink}
              variant="centeredPrimary"
              to="/about#how-it-works"
              sx={{
                backgroundColor: "primary.main",
                m: 2,
                transition: "background .6s",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              <Typography variant="h5" sx={{ fontFamily: "Helvetica" }}>
                How it works
              </Typography>
            </Button>
          </CardContent>
          <CardMedia
            component="img"
            image={slogan}
            alt="Slogan"
            sx={{ maxWidth: "md", margin: "0 auto" }}
          />
        </Card>
      </Box>
      <Box p={4} sx={{ backgroundColor: "primary.light" }}>
        <Typography
          variant="h3"
          sx={{ color: "secondary.main", textAlign: "center", p: 4 }}
        >
          Gear Categories
        </Typography>
        <Grid
          container={true}
          spacing={{ xs: 2, md: 3, lg: 4 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
        >
          {patchCards}
        </Grid>
      </Box>
      <Box
        p={6}
        sx={
          {
            // height: 200,
            // backgroundColor: 'primary.light',
          }
        }
      >
        <Typography variant="h4" sx={{ color: "text.primary" }}>
          Currently available in Salt Lake City, Utah
        </Typography>
      </Box>
    </Container>
  );
};

export default Home;
