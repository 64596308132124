import { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material/";
import { Storage } from "aws-amplify";

const LoadableCardImage = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadedImage, setLoadedImage] = useState();

  async function getImgUrl(imgSrc, sizePreset) {
    setLoading(true);
    var imgUrl = await Storage.get(sizePreset + "/" + imgSrc, { expires: 900 });
    setLoading(false);
    setLoadedImage(imgUrl);
  }
  useEffect(() => {
    getImgUrl(props.imgSrc, props.imgSizePreset);
  }, []);

  return (
    <div>
      {loading && (
        <Box sx={{ flexGrow: 1, display: "flex", textAlign: "center" }}>
          <CircularProgress size="8rem" sx={{ margin: "0 auto" }} />
        </Box>
      )}
      {!loading && (
        <CardMedia
          component="img"
          image={loadedImage}
          alt={props.altText}
          sx={props.sx}
        />
      )}
    </div>
  );
};

export default LoadableCardImage;
