import { React, useEffect, useState } from "react";

import { Amplify, API, graphqlOperation, Storage } from "aws-amplify";
import awsExports from "../aws-exports";
import { listItems } from "../graphql/queries";

import { useSearchParams } from "react-router-dom";

import { HashLink } from "react-router-hash-link";

import CustomGridItem from "../components/CustomGridItem";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Container,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material/";
import LoadableCardImage from "../components/LoadableCardImage";

// import { DateRange } from "react-date-range";

Amplify.configure(awsExports);

// function useQuery() {
//   const search = useLocation();
//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }
const drawerWidth = 240;

const Gear = () => {
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState({
    dayOnePrice: 0,
    dailyPrice: 0,
    imgSrc: "placeholder.jpg",
  });
  const [currentModalImage, setCurrentModalImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadedItems, setLoadedItems] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [category, setCategory] = useState(searchParams.get("category"));

  const handleModalOpen = (item) => {
    setModalOpen(true);
    setCurrentItem(item);
  };

  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    fetchItems(category);
  }, []);

  async function fetchItems(category) {
    setLoading(true);
    let filter = {
      category: { eq: category },
    };

    try {
      var itemData = null;
      if (category !== null && category !== "") {
        itemData = await API.graphql(
          graphqlOperation(listItems, { filter: filter })
        );
      } else {
        itemData = await API.graphql(graphqlOperation(listItems));
      }
      const items = itemData.data.listItems.items;
      setItems(items);
      const lItems = await Promise.all(
        items.map(async (item) => {
          return (
            <CustomGridItem
              key={item.id}
              item={item}
              action={handleModalOpen}
              actionArgs={item}
            ></CustomGridItem>
          );
        })
      );
      setLoadedItems(lItems);
    } catch (err) {
      console.log("error fetching items", err);
    }
    setLoading(false);
  }
  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: null,
  //     key: "selection",
  //   },
  // ]);

  // async function getImgUrl(imgSrc) {
  //   const imgUrl = await Storage.get(imgSrc, { expires: 60 });
  //   setCurrentModalImage(imgUrl);
  // }

  function BasicGearModal() {
    return (
      <div>
        <Modal
          keepMounted
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ maxHeight: "100%", maxWidth: "100%" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: 360,
              maxHeight: "100%",
              width: "md",
              bgcolor: "background.paper",
              borderRadius: 4,
              boxShadow: 24,
              p: 3,
            }}
          >
            <LoadableCardImage
              imgSrc={currentItem.imgSrc}
              imgSizePreset="360w"
              imgAltText={currentItem.imgAlt}
              sx={{
                maxHeight: 320,
                margin: "0 auto",
                borderRadius: 2,
                objectFit: "contain",
              }}
            />
            <CardContent sx={{ maxHeight: 300, paddingBottom: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 2, lg: 2 }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                sx={{ fontFamily: "Roboto" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ backgroundColor: "primary.white" }}
                >
                  <Typography variant="h6" sx={{ textAlign: "left" }}>
                    {currentItem.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body1" sx={{ fontFamily: "Helvetica" }}>
                    {currentItem.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body1" sx={{ fontFamily: "Helvetica" }}>
                    ${currentItem.dayOnePrice.toFixed(2)} Day 1 + $
                    {currentItem.dailyPrice.toFixed(2)} per additional day
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body1" sx={{ fontFamily: "Helvetica" }}>
                    Quanity available:
                    {currentItem.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    sx={{
                      color: "white",
                      margin: "0 auto",
                      textAlign: "center",
                      transition: "background .6s",
                      backgroundColor: "primary.main",
                      "&:hover": {
                        backgroundColor: "primary.dark",
                      },
                    }}
                    component={HashLink}
                    to="/about#book"
                  >
                    Book Me!
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Box>
        </Modal>
      </div>
    );
  }

  return (
    <Container maxWidth="xl" disableGutters variant="fullHeight">
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Typography variant="h3" sx={{ p: 4, color: "text.primary" }}>
            Available Gear
          </Typography>
          <BasicGearModal />
          <Box
            sx={{
              backgroundColor: "primary.white",
              maxWidth: "xl",
              margin: "0 auto",
              marginLeft: 2,
              marginRight: 2,
              // maxHeight: 300,
            }}
          >
            <FormControl variant="standard" sx={{ m: 3 }}>
              <Grid
                container
                spacing={{ xs: 1, md: 2, lg: 4 }}
                columns={{ xs: 1, sm: 2, md: 4, lg: 5 }}
              >
                <Grid item xs={12}>
                  <InputLabel id="category-label">Category</InputLabel>
                  <Select
                    sx={{ minWidth: 180, fontFamily: "Rubik Mono One" }}
                    label="Category"
                    value={category || ""}
                    onChange={(e) => {
                      setCategory(e.target.value);
                      fetchItems(e.target.value);
                    }}
                  >
                    <MenuItem value="">
                      <em>All Items</em>
                    </MenuItem>
                    <MenuItem value={"backpack"}>Backpacks</MenuItem>
                    <MenuItem value={"hammock"}>Chairs/Hammocks</MenuItem>
                    <MenuItem value={"sleeping_bag"}>
                      Sleeping Bags/Pads
                    </MenuItem>
                    <MenuItem value={"sup_kayak"}>Sup/Kayak</MenuItem>
                    <MenuItem value={"tent"}>Tents</MenuItem>
                    <MenuItem value={"misc"}>Misc</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </FormControl>
          </Box>
          {loading && (
            <Box
              p={4}
              sx={{ flexGrow: 1, display: "flex", textAlign: "center" }}
            >
              <CircularProgress size="8rem" sx={{ margin: "0 auto" }} />
            </Box>
          )}
          {!loading && loadedItems.length <= 0 && (
            <Box p={4}>
              <Typography variant="h4">Sorry! No Results.</Typography>
            </Box>
          )}
          {!loading && (
            <Grid
              container
              spacing={{ xs: 2, md: 3, lg: 3 }}
              columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}
              p={2}
            >
              {loadedItems}
            </Grid>
          )}
        </Box>
      </Box>
      <br />
    </Container>
  );
};

export default Gear;
