import React from "react";

import { AppBar, Toolbar, Box, MenuItem, CardMedia } from "@mui/material/";

import { HelpOutlined } from "@mui/icons-material";

import HikingIcon from "@mui/icons-material/Hiking";

import crux_logo_mini from "../img/crux_logo_mini.png";

import { NavLink } from "react-router-dom";

const iconPadding = 2;

function Header() {
  return (
    <AppBar position="fixed" color="primary">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <MenuItem variant="header" component={NavLink} to="/">
            <CardMedia
              component="img"
              image={crux_logo_mini}
              alt="Crux Utah Rental Equipment Mini Logo"
              sx={{ maxHeight: 36, maxWidth: 36, pr: 2 }}
            />
            CRUX
          </MenuItem>
        </div>
        <div>
          <Box>
            <MenuItem
              variant="header"
              sx={{ display: "inline-flex" }}
              component={NavLink}
              to="/gear"
            >
              <HikingIcon sx={{ pr: iconPadding }} />
              Gear
            </MenuItem>
            <MenuItem
              variant="header"
              sx={{ display: "inline-flex" }}
              component={NavLink}
              to="/about"
            >
              <HelpOutlined sx={{ pr: iconPadding }} />
              About
            </MenuItem>
            {/* <MenuItem
              sx={{ display: "inline-flex" }}
              component={NavLink}
              to="/admin"
            >
              <AdminPanelSettingsOutlined sx={{ pr: iconPadding }} />
              Admin
            </MenuItem> */}
          </Box>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
