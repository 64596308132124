import { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material/";
import { Storage } from "aws-amplify";
import { CardActions } from "@mui/material";

const gridItemImageSizePreset = "360w";

// const s3cfurl = "https://d4sisy0co3k51.cloudfront.net/public/"; // test
const s3cfurl = "https://d19mg0lf0ogjff.cloudfront.net/public/"; // dev (prod)

const CustomGridItem = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadedImage, setLoadedImage] = useState();

  async function getImgUrl(imgSrc, sizePreset) {
    setLoading(true);
    // var imgUrl = await Storage.get(sizePreset + "/" + imgSrc, { expires: 900 });
    var imgUrl = s3cfurl + sizePreset + "/" + imgSrc;
    setLoading(false);
    setLoadedImage(imgUrl);
  }

  useEffect(() => {
    getImgUrl(props.item.imgSrc, gridItemImageSizePreset);
  }, []);

  return (
    <Grid item xs={4} sm={4} md={4} lg={4}>
      <Card sx={{ borderRadius: 4 }}>
        <CardActionArea
          onClick={() => {
            props.action(props.actionArgs);
            //   handleModalOpen(props.item);
          }}
        >
          {loading && (
            <Box
              p={4}
              sx={{ flexGrow: 1, display: "flex", textAlign: "center" }}
            >
              <CircularProgress size="4rem" sx={{ margin: "0 auto" }} />
            </Box>
          )}
          {!loading && (
            <CardMedia
              component="img"
              image={loadedImage}
              sx={{
                height: 300,
                backgroundColor: "primary.white",
                // maxWidth: "100%",
                objectFit: "contain",
              }}
              // alt={item.imgAlt}
            />
          )}
          <CardContent
            sx={{
              backgroundColor: "primary.light",
              // minHeight: "100%",
              height: 180,
            }}
          >
            <Typography variant="h6" sx={{}}>
              {props.item.name}
            </Typography>
            <Typography variant="body2">
              {props.item.shortDescription}
            </Typography>
            <Typography variant="body1">
              ${props.item.dayOnePrice.toFixed(2)} Day 1 + $
              {props.item.dailyPrice.toFixed(2)} per additional day
            </Typography>
          </CardContent>
          {/* </CardActions> */}
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default CustomGridItem;
