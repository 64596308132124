import React from "react";
import { Container, Grid, Typography, Stack } from "@mui/material";

import { NavLink } from "react-router-dom";

function Footer(props) {
  return (
    <Container
      maxWidth="false"
      sx={{
        display: "flex",
        backgroundColor: "primary.main",
        padding: 2,
        justifyContent: "space-between",
        color: "#ededee",
      }}
    >
      <Grid container spacing={1} p={2}>
        <Grid item xs={1} />
        <Grid item xs={6}>
          <Typography variant="h6">Contact:</Typography>
          <br />
          <a className="footerlink" href="tel:8018897559">
            801-889-7559
          </a>
          <br />
          <a className="footerlink" href="mailto:CruxUtah@gmail.com">
            CruxUtah@gmail.com
          </a>
          <p>6145 S 520 E, Murray UT 84107</p>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={2}>
            <Typography variant="h6">Crux</Typography>
            <br />
            <NavLink className="footerlink" to="/about">
              About
            </NavLink>
            <NavLink className="footerlink" to="/gear">
              Gear
            </NavLink>
            <NavLink className="footerlink" to="/release">
              Crux Release of Liability
            </NavLink>
            <NavLink className="footerlink" to="/admin">
              Admin
            </NavLink>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <Typography variant="caption" sx={{ fontFamily: "Helvetica" }}>
            {props.appName} {props.gitVersion}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Footer;
