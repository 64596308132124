/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://md6tqz6o5vafvf4pg5gf7mploi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-fqmlfeqowzgifcshj43mejc47i",
    "aws_cognito_identity_pool_id": "us-east-1:b93f8516-b60b-4df2-8343-f611b48f727c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2gHGFgXeC",
    "aws_user_pools_web_client_id": "3aueno4ndv367f59qi9lm1drg6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "cruxrentalscom5a053d2bb2f14cdc99c07714bd5a98cf192828-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
