import { React, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import UploadIcon from "@mui/icons-material/Upload";

import { createItem } from "../graphql/mutations";
import { API, Storage } from "aws-amplify";
import { v4 as uuid } from "uuid";

const AddItem = ({ onUpload }) => {
  const [uploadState, setUploadState] = useState("");
  const [itemData, setItemData] = useState({
    name: "",
    description: "",
    shortDescription: "",
    itemPrice: 0,
    dayOnePrice: 0,
    dailyPrice: 0,
    quantity: 0,
    category: "misc",
  });
  const [imageData, setImageData] = useState({});
  const onError = (err) => {
    setUploadState(err);
  };

  const clearAddItem = () => {
    setItemData({
      name: "",
      description: "",
      shortDescription: "",
      itemPrice: 0,
      dayOnePrice: 0,
      dailyPrice: 0,
      quantity: 0,
      category: null,
    });
  };
  const uploadItemAsync = async () => {
    // console.log("itemData", itemData);
    const {
      name,
      description,
      shortDescription,
      itemPrice,
      dayOnePrice,
      dailyPrice,
      quantity,
      category,
    } = itemData;
    var { key } = await Storage.put(`${uuid()}.jpg`, imageData);
    const itemUuid = uuid();
    const createItemInput = {
      id: itemUuid,
      name,
      description,
      shortDescription,
      itemPrice,
      dayOnePrice,
      dailyPrice,
      quantity,
      imgSrc: key,
      category,
    };
    // console.log("createItemInput", createItemInput);
    try {
      await API.graphql({
        query: createItem,
        variables: { input: createItemInput },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      setUploadState("Success");
      onUpload();
      await clearAddItem();
    } catch (err) {
      console.log(err);
      onError("Upload error: " + err.errors[0].message);
    }
  };

  return (
    <Box p={4} sx={{ backgroundColor: "primary.white" }}>
      <Typography sx={{ p: 2 }} variant="h4">
        Add A New Item
      </Typography>
      <FormControl>
        <Grid
          container
          spacing={{ xs: 2, md: 3, lg: 4 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid item xs={5} sm={6} md={6} lg={6}>
            <TextField
              sx={{ width: "100%" }}
              label="Name"
              value={itemData.name}
              onChange={(e) =>
                setItemData({ ...itemData, name: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={7} sm={6} md={6} lg={6}>
            <TextField
              sx={{ width: "100%" }}
              multiline
              label="Short Description"
              value={itemData.shortDescription}
              onChange={(e) =>
                setItemData({ ...itemData, shortDescription: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              sx={{ width: "100%" }}
              multiline
              label="Description"
              value={itemData.description}
              onChange={(e) =>
                setItemData({ ...itemData, description: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <TextField
              sx={{ width: "100%" }}
              label="Item Price"
              value={itemData.itemPrice}
              type="number"
              step="0.01"
              min="0"
              onChange={(e) =>
                setItemData({ ...itemData, itemPrice: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <TextField
              sx={{ width: "100%" }}
              label="Day One Price"
              value={itemData.dayOnePrice}
              type="number"
              step="0.01"
              min="0"
              onChange={(e) =>
                setItemData({ ...itemData, dayOnePrice: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <TextField
              sx={{ width: "100%" }}
              label="Daily Price"
              value={itemData.dailyPrice}
              type="number"
              step="0.01"
              min="0"
              onChange={(e) =>
                setItemData({ ...itemData, dailyPrice: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <TextField
              sx={{ width: "100%" }}
              label="Quantity"
              value={itemData.quantity}
              type="number"
              step="1"
              min="0"
              onChange={(e) =>
                setItemData({ ...itemData, quantity: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              select
              variant="filled"
              sx={{ width: "100%" }}
              label="Category"
              value={itemData.category || ""}
              onChange={(e) =>
                setItemData({ ...itemData, category: e.target.value })
              }
            >
              <MenuItem value={"backpack"}>Backpacks</MenuItem>
              <MenuItem value={"hammock"}>Chairs/Hammocks</MenuItem>
              <MenuItem value={"sleeping_bag"}>Sleeping Bags/Pads</MenuItem>
              <MenuItem value={"sup_kayak"}>Sup/Kayak</MenuItem>
              <MenuItem value={"tent"}>Tents</MenuItem>
              <MenuItem value={"misc"}>Misc</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Button variant="contained" component="label" sx={{ p: 3 }}>
              <AddPhotoAlternateIcon />
              Choose Image
              <input
                sx={{ width: "100%" }}
                type="file"
                hidden
                accept="image/jpeg"
                label="Upload Image"
                onChange={(e) => setImageData(e.target.files[0])}
              />
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              sx={{
                color: "primary.white",
                backgroundColor: "primary.dark",
                p: 3,
                transition: "background .6s",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                textAlign: "center",
              }}
              onClick={uploadItemAsync}
            >
              <UploadIcon sx={{ marginRight: 2 }} />
              Upload Item
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              sx={{ width: "100%" }}
              disabled
              variant="standard"
              label="Upload result will display here..."
              value={uploadState}
            />
          </Grid>
        </Grid>
      </FormControl>
    </Box>
  );
};

export default AddItem;
