import React from "react";

import {
  Box,
  CardMedia,
  Container,
  List,
  ListItem,
  Typography,
} from "@mui/material/";

import TodayIcon from "@mui/icons-material/Today";
import BackpackIcon from "@mui/icons-material/Backpack";
import SystemSecurityUpdateGoodIcon from "@mui/icons-material/SystemSecurityUpdateGood";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HikingIcon from "@mui/icons-material/Hiking";

import aboutUs from "../img/aboutUs.jpg";

const About = () => {
  return (
    <Container variant="fullHeight" maxWidth="md" disableGutters>
      <Box
        sx={{
          maxHeight: 1600,
          backgroundColor: "primary.light",
          p: 4,
        }}
      >
        <Typography variant="h3" sx={{ color: "secondary.main" }}>
          Who We Are
        </Typography>

        {/* TODO: ADD THIS IMAGE HERE */}
        <Box m={2} sx={{ backgroundColor: "white", borderRadius: 6 }}>
          <CardMedia
            component="img"
            maxHeight={360}
            image={aboutUs}
            sx={{ image: "cover" }}
          ></CardMedia>
          <Typography p={4}>
            <Typography variant="body1" sx={{ color: "text.primary" }}>
              Allow us to introduce ourselves…
            </Typography>
            <br />
            <Typography variant="body1" sx={{ color: "text.primary" }}>
              We are Lindsey and Jeff, who share a passion for exploring and
              adventuring in many aspects in nature. We love bringing our dogs,
              kids, family and friends to explore with us. We both currently
              work in the healthcare field, Lindsey in mental health and Jeff in
              physical therapy. We focus non work time to nature as a priority
              to clear the mind and feed the soul. That is what inspires us to
              get into the mountains, the red rocks and the many other outdoor
              exploration opportunities that surround us.
            </Typography>
            <br />
            <Typography variant="body1" sx={{ color: "text.primary" }}>
              We created Crux Utah because over the years of adventuring we have
              a lot of gear and knowledge to share and have learned quality gear
              equals better experiences. We also understand that not everyone
              wants to spend big money for products they don’t use frequently or
              simply don’t have the room to store all of the gear. Enter Crux
              Utah Rental Equipment as a type of ‘cure’ for these situations.
            </Typography>
            <br />
            <Typography variant="body1" sx={{ color: "text.primary" }}>
              We are here to help people with the best gear and best fit for
              your outdoor adventure needs. From the first time adventurer
              looking to try out quality gear through the experienced
              mountaineer needing to disappear off grid, we have the gear for
              maximizing your adventures...Elevated Gear for Outdoor Exploration
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          maxHeight: 1000,
          backgroundColor: "white",
          p: 4,
        }}
      >
        <Typography variant="h3" sx={{ color: "secondary.main" }}>
          <div id="how-it-works">How It Works</div>
        </Typography>
        <Box
          m={2}
          p={4}
          sx={{
            backgroundColor: "primary.white",
            borderRadius: 6,
            maxWidth: 400,
            margin: "0 auto",
          }}
        >
          <Box sx={{ maxWidth: 400, margin: "0 auto" }}>
            <List sx={{}}>
              <ListItem>
                <TodayIcon sx={{ color: "text.primary" }} />
                <Typography
                  p={1}
                  variant="body1"
                  sx={{ color: "text.primary" }}
                >
                  Select your dates
                </Typography>
              </ListItem>
              <ListItem>
                <BackpackIcon sx={{ color: "text.primary" }} />
                <Typography
                  p={1}
                  variant="body1"
                  sx={{ color: "text.primary" }}
                >
                  Choose what you need
                </Typography>
              </ListItem>
              <ListItem>
                <SystemSecurityUpdateGoodIcon sx={{ color: "text.primary" }} />
                <Typography
                  p={1}
                  variant="body1"
                  sx={{ color: "text.primary" }}
                >
                  Complete reservations
                </Typography>
              </ListItem>
              <ListItem>
                <DirectionsCarIcon sx={{ color: "text.primary" }} />
                <Typography
                  p={1}
                  variant="body1"
                  sx={{ color: "text.primary" }}
                >
                  Pick up the gear directly from us
                </Typography>
              </ListItem>
              <ListItem>
                <HikingIcon sx={{ color: "text.primary" }} />
                <Typography
                  p={1}
                  variant="body1"
                  sx={{ color: "text.primary" }}
                >
                  Have an epic time in quality gear
                </Typography>
              </ListItem>
            </List>
          </Box>
          <br />
          <Typography variant="body1" sx={{ color: "text.primary" }}>
            As always, let us know if you have questions, need packs sized, set
            up guidance or trip recommendations or if looking for anything not
            listed
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          maxHeight: 1000,
          backgroundColor: "primary.light",
          p: 4,
        }}
      >
        <Typography variant="h3" sx={{ color: "secondary.main" }}>
          <div id="book">Book Your Gear</div>
        </Typography>
        <Box m={2} sx={{ backgroundColor: "white", borderRadius: 6 }}>
          <Typography variant="body1" p={4} sx={{ color: "text.primary" }}>
            Currently, online booking is not available. Please call or text us
            at <a href="tel:8018897559">801-889-7559</a> to book gear!
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          maxHeight: 1000,
          backgroundColor: "white",
          p: 4,
        }}
      >
        <Typography variant="h3" sx={{ color: "secondary.main" }}>
          <div id="contact-us"> Contact Us</div>
        </Typography>
        <Box>
          <Typography variant="body1" p={4} sx={{ color: "text.primary" }}>
            For questions, advice, or to book gear, contact us by phone at{" "}
            <a href="tel:8018897559">801-889-7559</a> or by email at{" "}
            <a href="mailto:CruxUtah@gmail.com">CruxUtah@gmail.com</a>.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default About;
